import React from "react";
import Accordion from "./Accordion";
import { useState } from "react";
import styles from "./AccordionContent.module.css";
import "../style/style.css";

const AccordionContent = () => {
  const [accordions, setAccordion] = useState([
    {
      key: 1,
      title: `Wie funktioniert der WhatsApp-Chat mit CUPRA und wer berät mich?`,
      data: `Du kannst dich mit allen individuellen Fragen und Wünschen direkt per WhatsApp-Chat an uns wenden. Öffne WhatsApp, indem du den QR-Code scannst oder auf den oben aufgeführten Link klickst und schick uns eine Nachricht. Unsere Expertinnen und Experten beraten dich zu deinem Anliegen und liefern dir schnelle Antworten auf deine Fragen.`,
      isOpen: false,
    },
    {
      key: 2,
      title: `Steht mir der WhatsApp-Chat jederzeit zur Verfügung?`,
      data: `Wir sind von Montag bis Freitag zwischen 8 und 19 Uhr sowie Samstag zwischen 9 und 16:30 Uhr auf WhatsApp erreichbar. Wenn du uns außerhalb unserer Servicezeiten schreibst, erhältst du Hilfe von unserem Digital Assistant. Oder hinterlasse unseren Expertinnen und Experten eine Nachricht. Sie melden sich umgehend persönlich bei dir.`,
      isOpen: false,
    },
    {
      key: 3,
      title: `Kann ich per WatsApp-Chat auch Bilder, Screenshots, Dokumente oder Links teilen?`,
      data: `Das Teilen von Bildern, Dokumenten und anderen Medien zählt zu einem der vielen Vorteile von WhatsApp. Um dein Anliegen besser klären oder deine Frage besser beantworten zu können, kannst du daher gerne Bilder, Screenshots, Links oder PDF-Dokumente im Chat mit uns teilen.`,
      isOpen: false,
    },
    {
      key: 4,
      title: `Kann ich den WhatsApp-Chat jederzeit öffnen, wenn ich ein Anliegen habe?`,
      data: `Du kannst den Chat jederzeit nahtlos fortsetzen. Egal, ob du eine Folgefrage oder ein neues Anliegen hast. Wir stehen dir kontinuierlich in diesem Chat zur Verfügung. Zusätzlich bleibt der Chatverlauf auch nach Abschluss der Unterhaltung auf deinem Gerät gespeichert und ist jederzeit für dich einsehbar. So hast du die Möglichkeit, auch später auf die bereitgestellten Informationen zurückzugreifen.`,
      isOpen: false,
    },
    {
      key: 5,
      title: `Ich möchte kein WhatsApp nutzen. Welche weiteren Möglichkeiten der Kontaktaufnahme gibt es?`,
      data: 'Falls du den WhatsApp-Chat nicht nutzen oder uns lieber auf einem anderen Weg kontaktieren möchtest, ist das kein Problem. Wir bieten diverse andere Kontaktmöglichkeiten an. Eine umfassende Übersicht findest <a target="_blank" href="https://www.cupraofficial.de/kontakt" rel="noreferrer">hier</a>.',
      isOpen: false,
    },
  ]);

  const toggleAccordion = (accordionkey: number) => {
    const updatedAccordions = accordions.map((accord) => {
      if (accord.key === accordionkey) {
        return { ...accord, isOpen: !accord.isOpen };
      } else {
        return { ...accord, isOpen: false };
      }
    });

    setAccordion(updatedAccordions);
  };

  return (
    <div className={`Container ${styles.AccordionContainer}`}>
      <h1 className={styles.AccordionTitle}>FAQ</h1>
      <div className={styles.QAContainer}>
        {accordions.map((accordion) => (
          <Accordion
            key={accordion.key}
            title={accordion.title}
            data={accordion.data}
            isOpen={accordion.isOpen}
            toggleAccordion={() => toggleAccordion(accordion.key)}
          />
        ))}
      </div>
    </div>
  );
};

export default AccordionContent;
