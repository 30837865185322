import styles from "./Info.module.css";
import "../style/style.css";

const TextBlock = (props: {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  halfSize?: boolean;
}) => {
  return (
    <div className="Container">
      <div className={`${styles.TextBlock}`}>
        <h2 className={styles.TextBlockTitle}>{props.title}</h2>
        {props.subtitle && (
          <h2 className={styles.TextBlockTitle}>{props.subtitle}</h2>
        )}
        <p className={styles.TextBlockInfo}>{props.children}</p>
      </div>
    </div>
  );
};

const Datenschutz = () => {
  return (
    <div>
      <div style={{ paddingBottom: "10em" }}>
        <TextBlock title="Datenschutz.">
          Den Schutz deiner privaten Daten nehmen wir sehr ernst. Für deine
          Anfragen bieten wir WhatsApp als Kommunikationskanal an. Er wird von
          WhatsApp Ireland Limited bereitgestellt. Wir möchten dich darauf
          hinweisen, dass bei der Nutzung von WhatsApp personenbezogene Daten
          gemäß der Datenschutzrichtlinien von WhatsApp Ireland Limited
          verarbeitet werden.
          <br />
          <br />
          Ausführliche Informationen findest in unserem{" "}
          <a
            href="https://www.cupraofficial.de/datenschutz"
            target="_blank"
            rel="noreferrer"
          >
            Datenschutz
          </a>{" "}
          und in der offiziellen WhatsApp{" "}
          <a
            href="https://www.whatsapp.com/legal/business-data-transfer-addendum/?lang=de"
            target="_blank"
            rel="noreferrer"
          >
            Datenschutzerklärung
          </a>
          .
        </TextBlock>
      </div>
    </div>
  );
};

export default Datenschutz;
